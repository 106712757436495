import React from 'react';

import './index.css';

import LinkButton from '@/components/button-like/link-button';
import Text from '@/components/text';
import { useClassnames } from '@/hooks/use-classnames';

import CareerDirectionCard from '../CareerDirectionCard';


import { IDirection, IVacancy } from '@/types/strapi/vacancies';


interface IProps {
    directions: Array<IDirection>,
    vacancies: Array<IVacancy>
}

const CareerDirections: React.FC<IProps> = ({ directions, vacancies }) => {
    const cn = useClassnames();

    return (
        <section className={cn('career-directions')}>
            <div className={cn('career-directions__wrapper')}>
                <div className={cn('career-directions__header')}>
                    <h3
                        className={cn('career-directions__heading')}
                    >
                        Выберите направление, <br />которое вам интересно
                    </h3>
                    <Text className={cn('career-directions__text')} size={3}>
                        Сегодня <span className="selection-pattern">Navio</span>&nbsp;&mdash; это 800+ высококлассных специалистов, обладающих самыми разными компетенциями.
                        Мы&nbsp;активно растём и&nbsp;ждём в&nbsp;команду тех, кто, как и&nbsp;мы, стремится изменить индустрию
                        транспорта и&nbsp;сделать комфортнее жизнь миллионов людей.
                    </Text>
                </div>
                <div className={cn('career-directions__grid')}>
                    {directions.map((el, index) => <CareerDirectionCard key={index} vacancies={vacancies} direction={el} />)}
                </div>
                <LinkButton
                    href="/vacancies"
                    isGatsbyLink={true}
                    className={cn('career-directions__button')}
                    isBlock={false}
                    size="s"
                >
                    Все вакансии
                </LinkButton>
            </div>
        </section>
    );
};

export default CareerDirections;
