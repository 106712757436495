import React from 'react';

import './index.css';
import MapImage from './assets/map.png';
import MapMobileImage from './assets/map-mobile.png';

import { useClassnames } from '@/hooks/use-classnames';

const CareerGeography = () => {
    const cn = useClassnames();

    return (
        <div className={cn('career-geography')}>
            <h3 className={cn('career-geography__heading')}>
                Наша география
            </h3>
            <picture className={cn('career-geography__image')}>
                <source srcSet={MapMobileImage} media="(max-width: 800px)" />
                <img src={MapImage} />
            </picture>
        </div>
    );
};

export default CareerGeography;
