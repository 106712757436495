import React from 'react';

import './index.css';

import LinkButton from '@/components/button-like/link-button';
import Heading from '@/components/heading';
import Text from '@/components/text';
import { useClassnames } from '@/hooks/use-classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Arrow from '@/images/slider-arrow.inline.svg';

interface IItem {
    image: string,
    title: string,
    text: string
}

interface IProps {
    title: string,
    data: Array<IItem>
}

const CareerWorth: React.FC<IProps> = ({ title, data }) => {
    const cn = useClassnames();

    return (
        <section className={cn('career-worth')}>
            <div className={cn('career-worth__wrapper')}>
                <div className="career-worth__header">
                    <h3
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Swiper
                        modules={[Navigation]}
                        navigation={{
                            prevEl: '.career-worth__nav-item_prev',
                            nextEl: '.career-worth__nav-item_next'
                        }}
                        loop={true}
                        spaceBetween={20}
                        slidesPerView={2}
                        className={cn('career-worth__list')}
                        breakpoints={{
                            1440: {
                                slidesPerView: 3
                            },
                            1250: {
                                slidesPerView: 2
                            }
                        }}
                    >
                        {data.map((el: IItem, index: number) => {
                            return (
                                <SwiperSlide key={index} className={cn('career-worth__item')}>
                                    <div className={cn('career-worth__item-image')}>
                                        <img
                                            src={el.image}
                                            alt="banner-image"
                                        />
                                    </div>
                                    <Heading
                                        className={cn('career-worth__item-title')}
                                        level={4}
                                        as="h4"
                                        dangerouslySetInnerHTML={{ __html: el.title }}
                                    />
                                    <Text
                                        size={3}
                                        className={cn('career-worth__item-text')}
                                        dangerouslySetInnerHTML={{ __html: el.text }}
                                    />
                                </SwiperSlide>
                            );
                        })}
                        <div className="career-worth__nav">
                            <Arrow className="career-worth__nav-item career-worth__nav-item_prev" />
                            <Arrow className="career-worth__nav-item career-worth__nav-item_next" />
                        </div>
                    </Swiper>
                </div>
                <div className={cn('career-worth__list_mobile')}>
                    {data.map((el: IItem, index: number) => {
                        return (
                            <div key={index} className={cn('career-worth__item')}>
                                <div className={cn('career-worth__item-image')}>
                                    <img
                                        src={el.image}
                                        alt="banner-image"
                                    />
                                </div>
                                <Heading
                                    className={cn('career-worth__item-title')}
                                    level={4}
                                    as="h4"
                                    dangerouslySetInnerHTML={{ __html: el.title }}
                                />
                                <Text
                                    size={3}
                                    className={cn('career-worth__item-text')}
                                    dangerouslySetInnerHTML={{ __html: el.text }}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <LinkButton
                className={cn('career-worth__button')} title="Смотреть вакансии" isGatsbyLink={true}
                size="s"
                href="/vacancies"
            >
                Все вакансии
            </LinkButton>
        </section>
    );
};

export default CareerWorth;
