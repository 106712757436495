import cn from 'classnames';
import React from 'react';

import ResumeButtonWrapper from '../../../vacancy/components/button-wrapper-resume';

import './index.css';

import LinkButton from '@/components/button-like/link-button';
import { Picture } from '@/components/Picture';
import { useClassnames } from '@/hooks/use-classnames';

interface IButtonsProps {
    classNames?: string,
    buttonText?: string
}

interface IProps {
    title: string,
    buttonText?: string
}

const imageKv = {
    desktopNormal: '/career/banner.png',
    tablet       : '/career/banner.png',
    mobile       : '/career/banner.png'
};

const CareerBannerButtons: React.FC<IProps> = ({ classNames, buttonText }: IButtonsProps) => {
    const cn = useClassnames();

    return (
        <div className={cn('career-banner__buttons', classNames)}>
            <LinkButton
                href="/vacancies"
                isGatsbyLink={true}
                className="career-banner__button"
                isBlock={false}
                size="s"
            >
                {buttonText}
            </LinkButton>
            <ResumeButtonWrapper
                className="career-banner__button"
                variant="transparent"
            >
                Хочу отправить резюме
            </ResumeButtonWrapper>
        </div>
    );
};

const CareerBanner: React.FC<IProps> = ({
    title,
    buttonText
}) => {
    const cn = useClassnames();

    return (
        <section className={cn('career-banner')}>
            <div className={cn('career-banner__content')}>
                <h1 className={cn('career-banner__title')} dangerouslySetInnerHTML={{ __html: title }} />
                <CareerBannerButtons
                    buttonText={buttonText}
                />
            </div>
            <Picture
                className={cn('career-banner__kv')}
                image={imageKv}
                alt="banner-image"
            />
            <CareerBannerButtons
                classNames="career-banner__buttons_mobile"
                buttonText={buttonText}
            />
        </section>
    );
};

export default CareerBanner;
