import React, { useContext, useCallback } from 'react';

import { appContext } from '../../../../context/context';
import { useClassnames } from '../../../../hooks/use-classnames';
import Button from '../../../button-like/button';

import './index.css';

interface IProps {
    children?: React.ReactNode,
    className?: string,
    variant: 'primary' | 'secondary' | 'transparent'
}

const ResumeButtonWrapper = ({ variant = 'primary', className, children }: IProps) => {
    const cn = useClassnames();

    const { isResumeFormVisible, setIsResumeFormVisible } = useContext(appContext);

    const setIsPopupVisibleHandler = useCallback(() => {
        setIsResumeFormVisible(true);
    }, []);

    return (
        <Button
            className={cn(className, 'button-wrapper', { 'button-wrapper_is-visible-on-tablet': !isResumeFormVisible })}
            type="button"
            buttonSize="s"
            variant={variant}
            isBlock={true}
            onClick={setIsPopupVisibleHandler}
        >
            {children}
        </Button>
    );
};


export default ResumeButtonWrapper;
