import { GetServerDataReturn } from 'gatsby';
import React from 'react';


import CareerPage from '../components/CareerPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';

import { IGlobalServerRuntimeData } from '@/types/strapi/global';
import { IPostType, TBlogPost } from '@/types/strapi/posts';
import { IDirection, IVacancy } from '@/types/strapi/vacancies';

type TVacanciesHot = Pick<IVacancy, 'id' | 'isHot' | 'title' | 'created_at' | 'city' | 'tags'>;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    directions: Array<IDirection>,
    vacancies: Array<IVacancy>,
    vacanciesHot: Array<TVacanciesHot>,
    posts: Array<TBlogPost>,
    postTypes: Array<IPostType>
}

type TProp = TPageProp<IServerRuntimeData>;

const Career: React.FC<TProp> = ({ serverData }) => {
    return (
        <CareerPage data={serverData} />
    );
};

export default Career;

const query = `
  query Global ($locale: String) {
    ${GLOBAL_QUERY}
      vacanciesHot: vacancies(where: {isSecret: false, isArchived: false, isHot: true}, locale: "ru") {
        id
        title
        isHot
        created_at
        city {
          text
        }
        tags {
          id
          text
        }
      }
      vacancies(where: {isSecret: false, isArchived: false}, locale: $locale) {
        id
        locale
        area {
          text
          value
          id
        }
        city {
          text
          value
          id
        }
        jobType {
          text
          value
          id
        }
        tags {
          text
          value
          id
        }
        title
        direction {
          header
          id
        }
        id
      }
      directions(locale: $locale,sort: "sort:desc") {
        header
        description
        id
      }
      posts (sort: "date:desc", start: 0) {
        id
        title
        date
        readingTime
        previewImage {
            desktopSmall {
              url
            }
            desktopNormal {
              url
            }
            mobile {
              url
            }
            tablet {
              url
            }
          alt
        }
        author {
          image {
            desktopSmall {
              url
            }
            desktopNormal {
              url
            }
            mobile {
              url
            }
            tablet {
              url
            }
            alt
          }
          name
          surname
        }
        tags(sort: "sort:asc") {
          id
          value
          text
        }
        type {
          value
          text
        }
      }
      postTypes (locale: $locale, sort: "sort:asc")  {
        id
        pluralText
        value
      }
  }
`;

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        return {
            props: {
                navPanel    : data.data.navPanel,
                global      : data.data.global,
                vacancies   : data.data.vacancies,
                vacanciesHot: data.data.vacanciesHot,
                directions  : data.data.directions,
                respondForm : data.data.respondForm,
                posts       : data.data.posts,
                postTypes   : data.data.postTypes,
                footer      : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
